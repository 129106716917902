import React, { Component } from "react";
import Slider from "react-slick";
import "./Slick.css";
import "./Slick-theme.css";


function PauseOnHover() {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
            <h2>Secure Your Future Today</h2>
            <h4>Quantum computers are coming, and current encryption methods will be broken. Don't become a victim of "steal now, decrypt later" schemes.  We offer future-proof security with post-quantum cryptography, safeguarding your data before it's too late. Contact us to stay ahead of the curve.</h4>
        </div>
        <div>
          <h2>Be Quantum-Safe with </h2><a href="https://www.quantropi.com" target="_blank"><h2>Quantropi</h2></a>          
          <h4>PQTI/QALQIO is proud to announce our partnership with Quantropi, a leading provider of post-quantum cryptography solutions.
This collaboration strengthens our commitment to offering clients the most advanced cybersecurity solutions available. By leveraging Quantropi's cutting-edge technology, we can ensure your data remains secure even in the face of the quantum computing revolution.</h4>
        </div>
        <div>
          <h2>Collaborations with Leading Research Institutions</h2>
          <h4>We are committed to staying at the forefront of post-quantum cryptography (PQC). We believe collaboration with leading research institutions is crucial to developing and implementing the most effective solutions for our clients. That's why we're proud to announce our ongoing collaborations with esteemed organizations like:</h4>
          <h4>* <u>OneQuantum.ph</u>: OneQuantum/QCSP is a pioneering Philippine research institute focusing on quantum technologies, including PQC. This partnership ensures we have access to cutting-edge research and development in the field.</h4>
        </div>
      </Slider>
    </div>
  );
}

export default PauseOnHover;