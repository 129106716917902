import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = () => {
  return (
    <div>
      <h4 align="center">Why Quantropi?</h4>
      <ReactPlayer
        url="https://youtu.be/VzYLV0Z2Pcc"
        controls={true}
        width="450px"
        height="350px"
      />
    </div>
  );
};

export default VideoPlayer;