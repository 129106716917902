import React from 'react';

// Components
import Navigation from '../../components/Navigation/Navigation';
import Button from '../../components/Button/Button';
//import Testimonial from '../../components/Testimonial/Testimonial';
//import Partnership from '../../components/Partnership/Partnership';
import Question from '../../components/Question/Question';
import Footer from '../../components/Footer/Footer';
import Slider from '../../components/Sliders/PauseOnHover';
import Video1 from '../../components/VideoPlayer/Quantropi-1';
import Video2 from '../../components/VideoPlayer/Quantropi-2';
// Animation
import animation from '../animation';

// CSS files
import '../global.css';
import './Home.css';

// Images
import aboutImage from '../../assets/qalq-trans.png';
// import detailedAboutImage from '../../assets/detailed-about-image.png';
import iconPlaceholder from '../../assets/icon-placeholder.png';
//import backendNews from '../../assets/orgs/backend-news.png';
//import dostAsti from '../../assets/orgs/dost-asti.png';
//import dostPcieerd from '../../assets/orgs/dost-pcieerd.png';
//import ibmQuantum from '../../assets/orgs/ibm-quantum.png';
//import msuIitCcs from '../../assets/orgs/msu-iit-ccs-ec.png';
//import qil from '../../assets/orgs/qil.png';
//import usc from '../../assets/orgs/usc.png';


// Data files to display
//import { testimonialData } from '../../data/testimonialData';
//import { partnershipData } from '../../data/partnershipData';
import { questionData } from '../../data/questionData';

function Home() {
	animation('.hidden');
	return (
		<div className='home-container'>
			<Navigation />

			<div className='background'></div>

			{/* ==== Hero Section ==== */}            
			<div className='hero section'>
                <br/><br/>
				<h1 className='title'>Post-Quantum Technologies Inc</h1>
				<h4 className='hidden'>Collaborating to Secure your Future</h4>
                <br/><br/>
				<div className='hero-cta hidden'>
                    <a href='https://forms.gle/AVHijV7NkwZpnTVY6'>
                        <Button text='Contact Us' color='gradient' target='_blank' rel='noferrer' />
                    </a>
					<Button text='Learn More' color='outline' target='about' />
				</div>
                <div className='App'>
                    <br/><br/>
                    <Slider/>
                    <br/><br/><br/><br/>
                </div>
                <div className='two-column'>
                    
                    <div className='column-1'><Video1/></div>                    
                    <div className='column-2'><Video2/></div>
                </div>
			</div>			

			{/* ==== About Section ==== */}
			<div className='two-column section width' id='about'>
				{/* About Image */}
				<div className='column-1'><img src={ aboutImage } alt='About PQTI/QALQIO' /></div>
				{/* About Text */}
				<div className='about-text column-2'>
					<p className='caption'>About Us</p>
					<h2 className='hidden'>Post-Quantum Technology Inc</h2>
					<p className='hidden'>Preempt. Protect. Prepare.</p>
					<div className='about-details hidden'>
						<div className='about-details-content' >
							<img className='icon' src={iconPlaceholder} alt='icon' />
							<p><strong>Preempt</strong>: Let us help you check and take concrete action before a threat emerges. With post-quantum cryptography, we anticipate the future risks of quantum computing and act proactively to secure your data.</p>
						</div>
						<div className='about-details-content' >
							<img className='icon' src={iconPlaceholder} alt='icon' />
							<p><strong>Protect</strong>: Let us help you safeguard your valuable information from current cyber threats. We offer robust security solutions to keep your data safe today.</p>
						</div>
						<div className='about-details-content' >
							<img className='icon' src={iconPlaceholder} alt='icon' />
							<p><strong>Prepare</strong>: We emphasize that you need to be ready for what's coming. By implementing PQC solutions now, you're preparing for the future of cybersecurity and the potential disruption of quantum computing.</p>
						</div>
					</div>
                    <a href='https://forms.gle/AVHijV7NkwZpnTVY6'>
                        <Button text='Contact Us' color='gradient' target='_blank' rel='noferrer' />
                    </a>
				</div>
			</div>

			{/* ==== Detailed About Section ==== */}
			{/* <div className='center detailed-about-section width'> */}
				{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> */}
				{/* 3-Column Section */}
				{/* <div className='three-column'> */}
					{/* 1st Column */}
					{/* <div className='detailed-about-column text'> */}
						{/* <div className='detailed-about-point'> */}
							{/* Icon Here */}
							{/* <img className='icon' src={iconPlaceholder} alt='icon' /> */}
							{/* <p>Lorem Ipsum</p> */}
							{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
						{/* </div> */}
						{/* <div className='detailed-about-point'> */}
							{/* Icon Here */}
							{/* <img className='icon' src={iconPlaceholder} alt='icon' /> */}
							{/* <p>Lorem Ipsum</p> */}
							{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
						{/* </div> */}
					{/* </div> */}
					{/* 2nd Column */}
					{/* <div className='detailed-about-column'> */}
						{/* <img className='detailed-about-image' src={ detailedAboutImage } alt='About PQTI/QALQIO'/> */}
					{/* </div> */}
					{/* 3rd Column */}
					{/* <div className='detailed-about-column text'> */}
						{/* <div className='detailed-about-point'> */}
							{/* Icon Here */}
							{/* <img className='icon' src={iconPlaceholder} alt='icon' /> */}
							{/* <p>Lorem Ipsum</p> */}
							{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
						{/* </div> */}
						{/* // <div className='detailed-about-point'> */}
							{/* Icon Here */}
							{/* <img className='icon' src={iconPlaceholder} alt='icon' /> */}
							{/* <p>Lorem Ipsum</p> */}
							{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
						{/* </div>
					</div>
				</div>
			</div> */}

			{/* ==== FAQs ==== */}
			<div className='questions section width'>
				<p className='caption'>Frequently Asked Questions</p>
				<h2 className='title hidden'>FAQs</h2>
				<Question questions={questionData}/>
			</div>

			<Footer />

		</div>
	);
};

export default Home;