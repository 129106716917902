import React from 'react';
import 'leaflet/dist/leaflet.css';
//import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';

// Components
import Navigation from '../../components/Navigation/Navigation';
import Button from '../../components/Button/Button';
import Footer from '../../components/Footer/Footer';

// Styles
import '../global.css';
import './Workshop.css';

// Animation
import animation from '../animation';
export default function Workshop() {
  animation('.hidden');

  return (
    <div className='workshop-container'>
      <Navigation />
      <div className='hero section'>
        <h1 className='hidden'>Request a Demo</h1>
        <h1 className='workshop-title hidden'>To Learn About Our Solutions</h1>
        <div className='workshop-buttons'>
          <a href='https://forms.gle/AVHijV7NkwZpnTVY6'>
            <Button text='Request' color='gradient' target='_blank' rel='noferrer' />
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
}
