// FAQs data to be displayed
export const questionData = [
    {
        question: 'What is Quantum Computing?', 
        answer:'Quantum computing uses the bizarre rules of quantum mechanics to solve problems that are impossible for traditional computers. Imagine a computer that can be a 0 and a 1 at the same time - that\'s the power of quantum! While still in its early stages, it has the potential to revolutionize fields like medicine, materials science, and cryptography  -- which is where we come in!.'
    },
    {
        question: 'What does quantum computing bring as a threat to cybersecurity?',
        answer:'Quantum computers could potentially crack the encryption codes that currently safeguard our data online. These codes are based on complex math problems that are difficult for regular computers to solve. However, quantum computers could solve these problems much faster, potentially rendering current encryption useless. This means information we send and store online, like financial records or medical data, could become vulnerable to theft.  That\'s why companies like ours are developing post-quantum cryptography, new encryption methods designed to withstand the power of quantum computers.'
    }, 
    {
        question: 'What is Post-Quantum Cryptography?',
        answer: 'Post-quantum cryptography (PQC) is a new generation of encryption methods designed to be secure even when quantum computers become powerful. Think of it like this: Imagine your current locks use keys with complex patterns. Quantum computers are like super lockpicks, potentially able to bypass them. PQC creates entirely new lock designs, using different principles, that these "quantum lockpicks" can\'t crack. By implementing PQC solutions now, you\'re future-proofing your data security and staying ahead of the curve.'
    },
];